.not-found-page{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    padding:0 5%;
}
.not-found-page .container{
    width: 30%;
    min-width: 300px;
    margin: auto;
    text-align: center;
    .inline{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h1{
        font-size: max(85px, 8vw);
        color:#222;
    }
    .shared-halfLogo{
        width: max(75px, 7vw);
        margin-left: min(-7.5px, -0.5vw);
    }
    p{
        font-size: max(15px, 0.95vw);
        margin-bottom: max(10px, 0.75vw);
    }
    a{
        font-size: max(15px, 0.95vw);
        text-decoration: underline;
        color: var(--main-color);
        text-underline-offset: 5px;
    }
}

body.dark-mode .not-found-page{
    background: #1A1A1A;
    .container{
        h1, p{
            color:#fff;
        }
    }
}
