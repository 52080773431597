
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: none;
}

html{
  height: 100%;
}
h1, h2, h3, h4, h5, h6{
  font-weight: 600;
}
body{
    width: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    &::-webkit-scrollbar {
      width: 10px;
      @media(max-width:1475px){
        width: 9px;
      }
    }
    
    &::-webkit-scrollbar-track {
      background: #e2e2e2;
      border-radius: 1px;
    }
    &::-webkit-scrollbar-thumb {
      background-color:  #474c55;
      border-radius: 1px;
    }
}
p{
  line-height: 1.7;
}

.rec-swipable{
  align-items:stretch;
}
.rec-carousel-item-visible{
  margin:0;
  display: flex;
}

button{ cursor: pointer; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 5000s ease-in-out 0s;
}
input{
  font-family: 'Poppins', sans-serif;
}
.centered-text{ text-align: center; }
.middle-title{ font-size: max(20px, 1.75vw); }
.large-title{ font-size: max(22.5px, 2.1vw); }
.shared-text{ font-size: max(14px, 0.875vw); }

.shared-h5{
  font-size: max(14px, 0.85vw);
  color:var( --main-color);
  font-weight: 500;
  text-transform: uppercase;
}

.centered-item{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.hover-button{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  background: var(--main-color);
  color:#fff;
  font-size: max(14px, 0.85vw);
  padding:max(12px, 0.7vw) max(20px, 1.5vw);
  border-radius: max(25px, 2vw);
  min-width: max(7vw, 110px);
    &:hover{
      animation: pulse-white 0.7s ease-in-out;
      -webkit-animation: pulse-white 0.7s ease-in-out;
    }
}

.hover-button2{
  display: flex;
  align-items: center;
  color: var(--main-color);
  font-size: max(14px, 0.85vw);
  margin: max(7.5px, 0.5vw) 0; 
  svg{
      margin-left: max(7px, 0.5vw);
  }
  &:hover svg{
      animation: hvr-icon-wobble-horizontal 1s ease-in-out 1;
      -webkit-animation: hvr-icon-wobble-horizontal 1s ease-in-out 1;
  }
}

.rec-arrow,
.rec-arrow-left,
.rec-arrow-right{
    border: 0 !important;
    box-shadow: none !important;
    background: var(--main-color) !important;
    width: max(25px, 1.75vw) !important;
    height: max(25px, 1.75vw)!important;
    min-width: max(25px, 1.75vw) !important;
    min-height: max(25px, 1.75vw) !important;
    max-width: max(25px, 1.75vw) !important;
    max-height: max(25px, 1.75vw) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: max(15px, 1vw) !important;
    color:#fff !important;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      left:-15% !important;
      top:-15% !important;
      border:1px solid var(--main-color) !important;
      width: 130% !important;
      height: 130% !important;
      border-radius: 50% !important;
      z-index: -1;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
    }
    &:hover::before{
      left:0 !important;
      top:0 !important;
      width: 100% !important;
      height: 100% !important;
    }
}

//Animations-------------------------------------------------------------
@keyframes pulse-white{
  0% {
    box-shadow: 0 0 0 0 rgba(var(--main-rgb), 0.5);
  }
  70% {
    box-shadow: 0 0 0 max(12.5px, 1vw) rgba(128, 173, 217, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
  }
}
@-webkit-keyframes pulse-white{
  0% {
    box-shadow: 0 0 0 0 rgba(var(--main-rgb), 0.5);
  }
  70% {
    box-shadow: 0 0 0 max(12.5px, 1vw) rgba(128, 173, 217, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
  }
}

//Icon Wobble Horizontal -------------------
@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

//Icon push-------------------------
@-webkit-keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}


.shared-logo{
  content: url('./assets/shared/black-orange-logo.svg');
}
.shared-halfLogo{
  content: url('./assets/shared/halfLogo-orange-dark.svg');
}
.shared-loading div{
  background: var(--main-color) !important;
}

:root{
  --main-color:#EB8341;
  --main-rgb: 235, 131, 65;
  --dark-bg:#fff;
  --dark-body:#fff;
  --dark-border: #e5e5e5;
  --light-border: #f5f5f5;
  --dark-text: #3a3a3a;
}

body.dark-mode{
  color: #fff;
  background: #1A1A1A;
  --dark-bg:#313131;
  --dark-body:#1A1A1A;
  --dark-border: #313131;
  --light-border: #313131;
  --dark-text: #ddd;
  .shared-logo{
    content: url('./assets/shared/white-orange-logo.svg');
  }
  .shared-halfLogo{
    content: url('./assets/shared/halfLogo-orange-white.svg');
  }
}

body.theme-pink{
  --main-color:#8221D6;
  --main-rgb: 130, 33, 214;
  .shared-logo{
    content: url('./assets/shared/black-pink-logo.svg');
  }
  .shared-halfLogo{
    content: url('./assets/shared/halfLogo-pink-dark.svg');
  }
  &.dark-mode .shared-logo{
    content: url('./assets/shared/white-pink-logo.svg');
  }
  &.dark-mode .shared-halfLogo{
    content: url('./assets/shared/halfLogo-pink-white.svg');
  }
}
body.theme-red{
  --main-color:#D53333;
  --main-rgb: 213, 51, 51;
  .shared-logo{
    content: url('./assets/shared/black-red-logo.svg');
  }
  .shared-halfLogo{
    content: url('./assets/shared/halfLogo-red-dark.svg');
  }
  &.dark-mode .shared-logo{
    content: url('./assets/shared/white-red-logo.svg');
  }
  &.dark-mode .shared-halfLogo{
    content: url('./assets/shared/halfLogo-red-white.svg');
  }
}
body.theme-blue{
  --main-color:#427cff;
  --main-rgb: 66, 124, 255;
  .shared-logo{
    content: url('./assets/shared/black-blue-logo.svg');
  }
  .shared-halfLogo{
    content: url('./assets/shared/halfLogo-blue-dark.svg');
  }
  &.dark-mode .shared-logo{
    content: url('./assets/shared/white-blue-logo.svg');
  }
  &.dark-mode .shared-halfLogo{
    content: url('./assets/shared/halfLogo-blue-white.svg');
  }
}
body.theme-green{
  --main-color:#429E79;
  --main-rgb: 66, 158, 121;
  .shared-logo{
    content: url('./assets/shared/black-green-logo.svg');
  }
  .shared-halfLogo{
    content: url('./assets/shared/halfLogo-green-dark.svg');
  }
  &.dark-mode .shared-logo{
    content: url('./assets/shared/white-green-logo.svg');
  }
  &.dark-mode .shared-halfLogo{
    content: url('./assets/shared/halfLogo-green-white.svg');
  }
}
body.dark-mode .apply-page .form-container form a{
  color: #fff;
}
body.dark-mode .inline h6{
  color: #fff;
}
@media(max-width:768px){
    .middle-title{ font-size: 22.5px; }
    .shared-text, 
    .shared-h5
    .hover-button2{ 
        font-size: 15px; 
    }
}
.ContentAllCertifications{
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  .Certificate-ImageContainer{
      width: 100%;
      padding: 5% 1% 5% 1%;
      .Certifications-Image{
          width: max(56%,49vw);
      }
  }
}

.CCNA-Content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  .Content-Image-CCNAP{
      width: 71%;
      position: relative;
      .CCNA-Image{
          width: 79%;
      }
  }
}




