.loading-animation{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle{
        background: var(--main-color);
        width: max(22.5px, 1.5vw);
        height: max(22.5px, 1.5vw);
        border-radius: 50%;
        margin: max(7.5px, 0.5vw);
        animation: pulse2 0.75s infinite;
    }
    .circle2{
        animation-delay: 0.25s;
    }
    .circle3{
        animation-delay: 0.5s;
    }
}

@keyframes pulse2{
    0% {
      box-shadow: 0 0 0 0 rgba(var(--main-rgb), 0.75);
    }
    70% {
      box-shadow: 0 0 0 max(10px, 1vw) rgba(128, 173, 217, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
    }
}
@-webkit-keyframes pulse2{
    0% {
      box-shadow: 0 0 0 0 rgba(var(--main-rgb), 0.75);
    }
    70% {
      box-shadow: 0 0 0 max(10px, 1vw) rgba(128, 173, 217, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
    }
}